.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.MuiAutocomplete-hasClearIcon label {
  left : 10px;
}
/* .MuiInputLabel-marginDense{
  left : 25px !important;
} Need a better solutions this breaks other working places..-a- */

.MuiToolbar-regular {
  min-height: 45px !important;
}

tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.checkBoxlbl {
  color: rgb(0,0,0,0.5);
  margin-top : 25px;
  padding: 0px 25px;
}
legend {
  font-size: 16px !important;
  margin-bottom: 5px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiChip-root{
  background-color: #ff5722 !important;
  color: white !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
